import * as React from 'react'
import { HeadFC, Link } from 'gatsby'
import OffersForCategory from '../components/offers-for-category/offers-for-category'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { classNames } from '../utility/class-names'
import { Category } from '../contracts/categories'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useAuthStore } from '../stores/auth-store'
import { Offer } from '../contracts/offer'

export const Head: HeadFC = () => <title>Disco Deals</title>

export default (props: any) => {
  const user = useAuthStore(state => state.user)
  
  const offers: Record<string, Offer[]> = props.data.allOffer.group.reduce((prev: any, group: any) => {
    const category = group.edges[0].node.category
    prev[category] = group.edges.map((edge: any) => edge.node)
    return prev
  }, {})  

  const categories: Category[] = props.data.allCategory.edges
    .map((edge: any) => edge.node)
    .sort((a: Category, b: Category) => {
      // Sort by number of offers per category desc, then by name asc
      const aOffers = offers[a.name]
      const bOffers = offers[b.name]

      const lengthDiff = bOffers.length - aOffers.length
      return lengthDiff === 0
        ? a.name.localeCompare(b.name)
        : lengthDiff
    })

  return (
    <main>

      <div className="bg-gray-900 text-white">
        <div className="container relative overflow-hidden flex flex-row-reverse items-end md:items-center px-0">
          <StaticImage
            src='https://images.unsplash.com/photo-1586278500132-7c85dfbc51d6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxfDB8MXxhbGx8fHx8fHx8fA&ixlib=rb-1.2.1&q=80&w=1080&utm_source=unsplash_source&utm_medium=referral&utm_campaign=api-credit'
            alt='Disco deals hero'
            placeholder='blurred'
            objectFit='cover'
            objectPosition='50% 80%'
            className="h-[450px] sm:h-[500px]"
          />

          <div className={
            classNames(
              'px-4 md:px-8 absolute bg-gray-900/75 h-full flex flex-col justify-center',
              'md:relative md:bg-transparent'
            )
          }>
            <h2 className={classNames(
              'text-5xl text-center tracking-tight font-bold text-white',
              'xs:text-6xl',
              'sm:tracking-tight',
              'md:text-left',
              'xl:text-6xl xl:tracking-tight'
            )}>
              Discover discounts
              <span className='text-primary-400'> you didn't know you had</span>
            </h2>
            <p className="mt-4 text-lg sm:text-2xl text-center text-gray-200 font-light mb-8">Save money on everyday purchases using benefits from memberships you already hold.</p>
            <Link
              to={user ? '/memberships' : '/memberships/choose'}
              className={classNames(
                'button button-secondary inline-block text-xl font-normal px-8 py-4 text-center shadow-2xl whitespace-nowrap',
                'animate-heartbeat hover:animate-none'
              )}
            >
              Discover Your Discounts
              <ArrowRightIcon className='ml-2 w-6 inline-block' />
            </Link>
          </div>
        </div>
      </div>
      
      <section className="container mt-8">
        <h2 className="text-3xl my-4 text-center text-primary-600 dark:text-primary-400 font-semibold px-2">Latest offers</h2>

        {categories.map(category =>
          <div key={category.name} className='mb-8'>
            <OffersForCategory
              category={category}
              offers={offers[category.name]}
            />
          </div>
        )}
      </section>
    </main>
  )
}

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1600) {
      ...GatsbyImageSharpFluid
    }
    original {
      width
    }
  }
}
`

export const offerDisplayFragment = graphql`
fragment OfferDisplay on Offer {
  id
  category
  title
  description
  terms
  link
  offeredBy {
    name
    image {
      childImageSharp {
        gatsbyImageData(width: 320)
      }
    }
  }
}
`

export const query = graphql`
query {
  allCategory {
    edges {
      node {
        name
        image {
          childImageSharp {
            gatsbyImageData(width: 200)
          }
        }
      }
    }
  }
  allOffer {
    group(field: category, limit: 8) {
      edges {
        node {
          ...OfferDisplay
        }
      }
    }
  }
}
`
