import * as React from 'react'
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'
import { Offer } from '../../contracts/offer'
import OfferCardGrid from '../offer-card-grid/offer-card-grid'
import { Category } from '../../contracts/categories'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export interface OffersForCategoryProps {
  category: Category
  offers: Pick<Offer, 'id' | 'title' | 'offeredBy'>[]
}

export default ({ category, offers }: OffersForCategoryProps) => {
  const image = getImage(category.image ?? null)
  
  return (
    <div>
      <Link to={`/categories/${category.name.toLowerCase()}`} className='section-title relative'>
        <h2 className='text-2xl text-gray-800 flex items-center hover:text-primary-600'>
          {image &&
            <GatsbyImage
              image={image!}
              alt={category.name}
              objectFit='cover'
              className="category-image"
            />
          }
          <div>
            {category.name.replace(/-/g, ' ')}
            <span className='block text-xs text-primary-600'>More <ChevronDoubleRightIcon className='w-3 inline' /></span>
          </div>
        </h2>
      </Link>

      <div className="mt-6">
        <OfferCardGrid offers={offers} />
      </div>
    </div>
  )
}