import * as React from 'react'
import OfferCard from './offer-card'
import { Offer } from '../../contracts/offer'

export interface OfferCardGridProps {
  offers: Pick<Offer, 'id' | 'title' | 'offeredBy'>[]
}

export default ({ offers }: OfferCardGridProps) => {
  return (
    <div className='grid gap-1 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 auto-rows-[220px]'>
      {offers.map(offer =>
        <OfferCard key={offer.id} offer={offer} className='mx-2 mb-4' />
      )}
    </div>
  )
}