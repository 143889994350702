import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useState } from 'react'
import { Offer } from '../../contracts/offer'
import { classNames } from '../../utility/class-names'

export interface OfferCardProps {
  key?: string
  className?: string
  offer: Pick<Offer, 'id' | 'title' | 'offeredBy'>
}

export default ({ offer, className, ...rest }: OfferCardProps) => {
  const imageSrc = `/images/companies/${offer.offeredBy.id}.jpg`
  const image = getImage(offer.offeredBy.image)

  const [image404, setImage404] = useState(false)

  return (
    <Link
      to={`/offers/${offer.id.toLowerCase()}`}
      className={classNames(
        className,
        'card card-link grid grid-cols-1 auto-rows-fr',
      )}
      {...rest}
    >
      <div className='flex justify-center items-center h-full'>
        {image && 
          <GatsbyImage
            image={image}
            alt={offer.offeredBy.name}
            objectFit='contain'
            objectPosition='50% 100%'
            className="w-40 h-12 mb-2 bg-white border-4 border-white"
          />
        }
        {!image &&
          <img
            src={imageSrc}
            alt={offer.offeredBy.name}
            className={
              classNames(
                'px-8 max-h-12 mb-2 bg-white border-4 border-white',
                image404 ? 'hidden' : ''
              )
            }
            onError={() => setImage404(true)}
          />
        }
      </div>
      <p className='text-md md:text-lg text-gray-600 flex justify-center items-start text-center h-full overflow-hidden'>{offer.title}</p>
    </Link>
  )
}